import { shade } from 'polished';
import styled from 'styled-components';
import signInBackgroundImage from '../../assets/images/login-background.jpg';
import theme from '../../styles/material';

export const MainContainer = styled.div`
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: start;
  flex: 1;
  background-color: #f6f6f6; //url(${signInBackgroundImage}) no-repeat center;
  background-size: cover;
`;

export const Header = styled.div`
  background-color: #f9f9f9;
  border-left: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  color: #333;
  padding: 5px;
  padding-left: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
`;

export const HeaderChat = styled.div`
  border-right: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  background-color: #f9f9f9;
  color: #333;
  padding: 5px;
  padding-left: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 351px;
  right: 0;
  height: 60px;
`;
export const Profile = styled.div`
  font-size: 14px;
`;
export const MainContent = styled.div`
  //position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //max-width: 100%;
  height: 100vh;
  margin-left: 0%;
  color: ${theme.palette.text.main};

  @media (min-width: 350px) {
    width: 100%;
    height: 99%;
  }
`;
