import styled from 'styled-components';

export const Sidebar = styled.div`
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: 0;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  flex: 1;
  z-index: ${(props) => (props.open ? 1000 : -1000)};
  width: ${(props) => (props.open ? '350px' : '0px')};
  transform: scale3d();
  transition: width 500ms;
  color: #333;
  & > input {
    width: 100%;
    background: inherit;
    border: none;
    padding: 10px 20px;
  }
`;
export const Container = styled.div`
  position: absolute;
  top: 100px;
  bottom: 0px;
  width: 349px;
  opacity: ${(props) => (props.open ? 1 : 0)};
  overflow-x: hidden;
  overflow-y: scroll;
`;

export const ItemSelected = styled.div`
  background: #999;
  padding: 5px;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  margin: 5px;
`;

export const ItemList = styled.div`
  position: relative;
  background-color: #fff;
  padding: 15px;
  border-top: 2px solid #f9f9f9;
  border-bottom: 2px solid #f9f9f9;
  transition: background-color 0.8s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  color: #666;

  + div {
    border-top: none;
  }
  cursor: pointer;
  transition: background 500ms;

  &:hover {
    background-color: #d0d0d0 !important;
  }
  & + div {
    border-top: 0;
  }
`;

export const Header = styled.div`
  width: 350px;
  height: 60px;
  background: #f0f0f0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  column-gap: 10px;
  align-items: center;
`;
