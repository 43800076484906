/* eslint-disable prettier/prettier */
import { Button, IconButton, Menu, MenuItem, Select } from '@material-ui/core';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Chat from '../chat';

import ChatIcon from '@material-ui/icons/Chat';
import GroupIcon from '@material-ui/icons/Forum';
import ContactsIcon from '@material-ui/icons/Contacts';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { NativeSelect } from '@material-ui/core';
import { Icon, InlineIcon } from '@iconify/react';
import squareEditOutline from '@iconify-icons/mdi/square-edit-outline';
import {
  FaUser,
  FaUsers,
  FaSignOutAlt,
  FaGlobe,
  FaArchive,
} from 'react-icons/fa';
import {
  Input,
  Content,
  ConversationsContainer,
  ConversationItem,
  Header,
  Profile,
  HeaderChat,
  ButtonsContainer,
  DateTimeMessage,
  Status,
  ContactContainer,
  Reads,
  ButtonsContainerMenu,
  SearchInput,
  ItensContainerMenu,
  ArchiveContainer,
} from './styles';
import phoneImage from '../jitsi/molumen_phone_icon.png';
import { useConversation } from '../../hooks/conversation';
import { useAuth } from '../../hooks/auth';
import { useChatMessages } from '../../hooks/chat';
import ListContacts from '../../components/contacts';
import { useContacts } from '../../hooks/contacts';
import MenuSettings from '../../components/menuSettings';
import NewGroup from '../../components/newgroup';
import { convertBackendDate, getStringDate, truncateString } from '../../utils';
import MenuConversation from '../../components/menuConversation';
import Info from '../../components/info';
import { useGroups } from '../../hooks/groups';
import { useHistory } from 'react-router-dom';
import {
  getCallEvent,
  GetCallInformationUsingCallGuid,
  StartCallUsingContactInformation,
} from '../../services/conversations';

import Groups from '../groups';

import ChangeLanguage from '../../components/ChangeLanguage';

import { useIntl } from 'react-intl';
import Swal from 'sweetalert2';

import ringer from './facebook_call.mp3';

const Conversations = () => {
  const { user, portal, setPortal } = useAuth();

  // prettier-ignore
  const {
    conversations,
    archiveds,
    filterName,
    setFilterName,
    openInfo,
    setOpenInfo,
    reloadArchivedConversations,
  } = useConversation();
  const { reloadGroups, setMenuGroups } = useGroups();
  const history = useHistory();
  const {
    conversation,
    setSelecetedConversation,
    setMessages,
    clearConversation,
    selectedPage,
    setSelectedPage,
  } = useChatMessages();
  const [dataFiltered, setDataFiltered] = useState([]);
  const [archiveEffect, setArchiveEffect] = useState([]);
  const inputRef = useRef(null);
  const { setMenu } = useContacts();
  const intl = useIntl();
  const [callEvents, setCallEvents] = React.useState(null);
  const [ringerTune, setRingerTune] = React.useState(null);
  const [isIncomingCall, setIsIncomingCall] = React.useState(false);

  const audio = new Audio(ringer);
  // audio.loop = true;
  // check if any incoming call
  useEffect(() => {
    if (user && portal) {
      // const interval = setInterval(() => {
      const maxTimeOut = setTimeout(async () => {
        const data = await getCallEvent();
        Promise.resolve(data).then((data) => {
          if (data?.GetCallEventResult === true) {
            setIsIncomingCall(true);
            // set ringer
            setRingerTune(true);

            // play ringer
            audio.loop = true;
            audio.play();
            // console.log('ringer');
            // set call event
            setCallEvents(data?.callEvent);
            // set browser notification
            const notification = new Notification(
              'Call from ' + data?.callEvent?.ContactInformation?.Name,
              {
                body: 'Do you want to accept the call?',
              }
            );

            notification.onclick = () => {
              history.push({
                pathname: '/jitsi/' + data?.callEvent?.CallGuid,
                state: callEvents,
              });
            };

            // confirm call
            Swal.fire({
              title: 'Call from ' + data?.callEvent?.ContactInformation?.Name,
              text: 'Do you want to accept the call?',
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
            }).then((result) => {
              if (result.value) {
                audio.loop = false;
                // redirect to call page
                history.push({
                  pathname: '/jitsi/' + data?.callEvent?.CallGuid,
                  state: callEvents,
                });
              } else {
                audio.loop = false;
                // set ringer
                setRingerTune(false);
                // play ringer
                audio.pause();
                //
                const maxInterval = setInterval(() => {
                  setTimeout(() => {
                    getCallEventFromAPI();
                  }, 10000);
                }, 15000);
                if (isIncomingCall === true) {
                  return () => clearInterval(maxInterval);
                }
              }
            });
          } else {
            const maxInterval = setInterval(() => {
              setTimeout(() => {
                getCallEventFromAPI();
              }, 10000);
            }, 15000);
            if (isIncomingCall === true) {
              return () => clearInterval(maxInterval);
            }
          }
        });
      }, 10000);
      // }, 10000);
      return () => clearTimeout(maxTimeOut);
    }
  }, [user, portal]);

  // useEffect(() => {
  //   if (user && portal) {
  //     if (isIncomingCall === false) {
  //       setTimeout(() => {
  //         getCallEventFromAPI();
  //       }, 10000);
  //     }
  //   }
  // }, [user, portal, isIncomingCall]);

  // getCallEvent
  const getCallEventFromAPI = () => {
    const data = getCallEvent();
    Promise.resolve(data).then((data) => {
      if (data?.GetCallEventResult === true) {
        setIsIncomingCall(true);
        // set ringer
        setRingerTune(true);

        // play ringer
        audio.loop = true;
        audio.play();
        console.log('ringer');
        // set call event
        setCallEvents(data?.callEvent);
        // set browser notification
        const notification = new Notification(
          'Call from ' + data?.callEvent?.ContactInformation?.Name,
          {
            body: 'Do you want to accept the call?',
          }
        );

        notification.onclick = () => {
          history.push({
            pathname: '/jitsi/' + data?.callEvent?.CallGuid,
            state: callEvents,
          });
        };

        // confirm call
        Swal.fire({
          title: 'Call from ' + data?.callEvent?.ContactInformation?.Name,
          text: 'Do you want to accept the call?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }).then((result) => {
          if (result.value) {
            audio.loop = false;
            // redirect to call page
            history.push({
              pathname: '/jitsi/' + data?.callEvent?.CallGuid,
              state: callEvents,
            });
          } else {
            audio.loop = false;
            console.log('false');
          }
        });
      }
    });
  };
  // end check if any incoming call
  useEffect(() => {
    if (conversations) {
      // console.log(conversations[0]?.Owner.Name.toUpperCase());
      // console.log(conversations)
      setDataFiltered(
        conversations.filter((c) =>
          c.Owner.Name.toUpperCase().includes(filterName.toUpperCase())
        )
      );
      setSelectedPage(true);
    }
    // setInterval(() => {
    //   getCallEvent();
    // }, 5000);
  }, [conversations]);

  useEffect(() => {
    if (conversations) {
      // console.log(conversations)

      setArchiveEffect(archiveds);
      setSelectedPage(true);
    }
  }, [conversations]);

  const onKeyUp = (event) => {
    setFilterName(inputRef.current?.value);
  };
  const handleClickContacts = (event) => {
    clearConversation('');
    setSelectedPage(false);
    if (openInfo) setOpenInfo(false);
    history.push('/contacts');
  };
  const handleClickArchived = (event) => {
    clearConversation('');
    setSelectedPage(false);
    setOpenInfo(false);
    history.push('/archiveds');
  };

  const handleClickMessages = (event) => {
    history.push('/app');
  };

  const logout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    setPortal(null);
    history.push('/');
  }, [history, setPortal]);
  return (
    <>
      <Content>
        <Header>
          <ButtonsContainerMenu>
            <ItensContainerMenu
              title={intl.formatMessage({
                id: 'Contacts',
              })}
            >
              <ContactsIcon onClick={handleClickContacts} />
            </ItensContainerMenu>
            <ItensContainerMenu
              title={intl.formatMessage({ id: 'Messages' })}
              isSelected={selectedPage}
            >
              <ChatIcon onClick={handleClickMessages} />
            </ItensContainerMenu>
            <ItensContainerMenu title={intl.formatMessage({ id: 'Logout' })}>
              <FaSignOutAlt size={22} onClick={logout} />
            </ItensContainerMenu>
          </ButtonsContainerMenu>
          {/* <Profile>{user}</Profile> */}
          {intl.formatMessage({ id: 'Messages' })}

          <ButtonsContainerMenu>
            <ItensContainerMenu
              title={intl.formatMessage({ id: 'NewMessage' })}
            >
              <Icon
                icon={squareEditOutline}
                width="24px"
                height="24px"
                onClick={async () => {
                  await reloadGroups();
                  setMenu(true);
                }}
              />
            </ItensContainerMenu>
            {/* <ItensContainerMenu title={'Call History'}>
              <img
                src={phoneImage}
                alt=""
                style={{ height: '25px', cursor: 'pointer' }}
                onClick={() => history.push({ pathname: '/call/history' })}
              />
            </ItensContainerMenu> */}
            <ItensContainerMenu
              title={intl.formatMessage({ id: 'NewChartGroup' })}
            >
              <GroupIcon
                onClick={async () => {
                  setMenuGroups(true);
                }}
              />
            </ItensContainerMenu>
            <ItensContainerMenu title={intl.formatMessage({ id: 'Idioms' })}>
              <ChangeLanguage space="0rem" />
            </ItensContainerMenu>
          </ButtonsContainerMenu>
        </Header>
        <SearchInput
          ref={inputRef}
          onKeyUp={onKeyUp}
          placeholder={intl.formatMessage({ id: 'SearchHere' })}
        />
        <ConversationsContainer>
          {archiveEffect && archiveEffect.length > 0 && (
            <ConversationItem onClick={handleClickArchived}>
              <ArchiveContainer>
                <div style={{ display: 'flex', columnGap: 5 }}>
                  <FaArchive />
                  <span title={intl.formatMessage({ id: 'Archiveds' })}>
                    {truncateString(
                      intl.formatMessage({ id: 'Archiveds' }),
                      60
                    )}
                  </span>
                </div>
              </ArchiveContainer>
            </ConversationItem>
          )}
          {dataFiltered &&
            dataFiltered.length > 0 &&
            dataFiltered.map((c, key) => {
              let dateString = '';
              if (c.LastMessage) {
                dateString = convertBackendDate(c.LastMessage.MessageDateTime);
              }
              return (
                <ConversationItem
                  key={key}
                  onClick={() => {
                    setSelecetedConversation(c);
                    setMessages([]);
                  }}
                  isSelected={conversation && conversation.Guid === c.Guid}
                >
                  {c.NumberOfUnreadMessages !== 0 && (
                    <Reads>{c.NumberOfUnreadMessages}</Reads>
                  )}
                  <ContactContainer>
                    <div style={{ display: 'flex', columnGap: 5 }}>
                      {!c.isGroup && <FaUser />}
                      {c.isGroup && <FaUsers />}
                      <span title={c.Owner.Name}>
                        {truncateString(c.Owner.Name, 60)}
                      </span>
                    </div>
                    {c.isGroup && c.LastMessage && (
                      <h6
                        title={
                          c.LastMessage.ReferencedMessage &&
                          c.IsForward === true
                            ? c.LastMessage.ReferencedMessage.Message
                            : c.LastMessage.Message
                        }
                      >
                        {truncateString(
                          `${c.LastMessage.Sender.Name}: ${
                            c.LastMessage.ReferencedMessage &&
                            c.IsForward === true
                              ? c.LastMessage.ReferencedMessage.Message
                              : c.LastMessage.Message
                          }`,
                          60
                        )}
                      </h6>
                    )}
                    {!c.isGroup && c.LastMessage && (
                      <h6
                        title={
                          c.LastMessage.ReferencedMessage &&
                          c.IsForward === true
                            ? c.LastMessage.ReferencedMessage.Message
                            : c.LastMessage.Message
                        }
                      >
                        {truncateString(
                          c.LastMessage.ReferencedMessage &&
                            c.IsForward === true
                            ? c.LastMessage.ReferencedMessage.Message
                            : c.LastMessage.Message,
                          60
                        )}
                      </h6>
                    )}
                  </ContactContainer>
                  <DateTimeMessage unreadLabel={c.NumberOfUnreadMessages !== 0}>
                    {getStringDate(dateString)}
                  </DateTimeMessage>
                </ConversationItem>
              );
            })}
        </ConversationsContainer>
        {conversation && (
          <React.Fragment>
            <HeaderChat>
              <div>
                <Profile>
                  {!conversation.isGroup && !conversation.Owner.Name && (
                    <Status status={conversation.Owner.OnlineState} />
                  )}
                  {conversation.Owner?.Name}
                </Profile>
              </div>
              <MenuConversation />
            </HeaderChat>
            <Chat conversationId={conversation} />
          </React.Fragment>
        )}
        <Info />
        <ListContacts clearFilterChats={() => setFilterName('')} />
        <NewGroup clearFilterChats={() => setFilterName('')} />
      </Content>
    </>
  );
};
export default Conversations;
