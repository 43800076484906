import api from './api';
import { getInstanceId, getRegisterDevice } from '../storage';

const RetrievePushApplicationPublicKey = async () => {
  const RegisterDevice = await getRegisterDevice();
  const RegisterDeviceResult = RegisterDevice
    ? RegisterDevice.RegisterDeviceResult.Configuration.PlatformType
    : null;
  const PlatformType = RegisterDeviceResult ?? 4;
  const appType = parseInt(`${PlatformType}000`);

  const applicationServerPublicKey = (
    await api.post('/DiscoveryClient/ios/RetrievePushApplicationPublicKey', {
      instanceId: getInstanceId(),
      appType,
    })
  ).data.applicationServerPublicKey;
  return applicationServerPublicKey;
};
const RegisterWebPushNotificationId = async (params) => {
  const instanceId = getInstanceId();
  const RegisterDevice = await getRegisterDevice();
  const RegisterDeviceResult = RegisterDevice
    ? RegisterDevice.RegisterDeviceResult.Configuration.PlatformType
    : null;
  const PlatformType = RegisterDeviceResult ?? 4;
  const appType = parseInt(`${PlatformType}000`);
  const pushEndPoint = params.endpoint;
  const pushP256Dh = params.keys.p256dh;
  const pushAuth = params.keys.auth;
  return api
    .post('/DiscoveryClient/ios/RegisterWebPushNotificationId', {
      instanceId,
      appType,
      pushEndPoint,
      pushP256Dh,
      pushAuth,
    })
    .then((res) => res.data);
};
export { RetrievePushApplicationPublicKey, RegisterWebPushNotificationId };
