import React, { useEffect, useRef, useState } from 'react';
import Chat from '../chat';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FaUsers, FaUser } from 'react-icons/fa';
import { MainContainer, MainContent } from '../main/styles';
import {
  Content,
  ConversationsContainer,
  ConversationItem,
  Header,
  Profile,
  HeaderChat,
  Status,
  DateTimeMessage,
  ContactContainer,
  Reads,
  ButtonsContainerMenu,
  SearchInput,
  SearchArea,
  ItensContainerMenu,
} from '../archiveds/styles';
import { useConversation } from '../../hooks/conversation';
import { useChatMessages } from '../../hooks/chat';
import ListContacts from '../../components/contacts';
import NewGroup from '../../components/newgroup';
import { convertBackendDate, getStringDate, truncateString } from '../../utils';
import MenuConversation from '../../components/menuConversation';
import { useHistory } from 'react-router-dom';
import Info from '../../components/info';
import { useIntl } from 'react-intl';
import {
  StartCallUsingContactInformation,
  GetCallHistory,
} from '../../services/conversations';
import './styles.css';
import phoneImage from './missed-call_5336301.png';
import Swal from 'sweetalert2';
const GetCallHistoryPage = () => {
  const { archiveds, filterName, setFilterName, openInfo, setOpenInfo } =
    useConversation();
  const history = useHistory();
  const {
    conversation,
    setSelecetedConversation,
    setMessages,
    clearConversation,
    setSelectedPage,
    openChat,
    setOpenChat,
  } = useChatMessages();
  const [dataFiltered, setDataFiltered] = useState([]);
  const inputRef = useRef(null);
  const [incomingCallList, setIncomingCallList] = useState([]);
  const [outgoingCallList, setOutgoingCallList] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    if (archiveds) {
      // console.log(conversations[0]?.Owner.Name.toUpperCase());
      setDataFiltered(
        archiveds.filter((c) =>
          c.Owner.Name.toUpperCase().includes(filterName.toUpperCase())
        )
      );
      setSelectedPage(true);
    }
  }, [archiveds]);
  const callHistory = GetCallHistory();

  useEffect(() => {
    // if (incomingCallList.length === 0 && outgoingCallList.length === 0) {
    Promise.resolve(callHistory).then((data) => {
      if (data?.GetCallHistoryResult === true) {
        // set incoming call list
        setIncomingCallList(
          data?.incomingCalls?.map((call) => {
            console.log(
              data?.CallDetails && data.CallDetails[0]?.CallActionTime
            );
            return {
              ...call,
              date: data?.CallDetails && data.CallDetails[0]?.CallActionTime,
            };
          })
        );
        console.log(
          data?.incomingCalls?.map((call) => {
            return {
              ...call,
              date: data?.CallDetails && data.CallDetails[0]?.Date,
            };
          })
        );
        // set outgoing call
        setOutgoingCallList(data?.outGoingCalls);
      } else {
        // reset call list
        setIncomingCallList([]);
        setOutgoingCallList([]);
      }
    });
    // }
  }, []);

  const goBack = () => {
    clearConversation('');
    setSelectedPage(false);
    if (openInfo) setOpenInfo(false);
    history.goBack();
  };

  const onKeyUp = (event) => {
    setFilterName(inputRef.current?.value);
  };

  const makeCall = (StaffGuid, CallState) => {
    // set a  timeout to check if call is ready
    // setTimeout(() => {
    const makeCall = StartCallUsingContactInformation(
      StaffGuid, // callerID
      1 // 1 (for staffmember) 3 (for staffmembergroup)
    );
    // promise fullfilled => call is in progress
    Promise.resolve(makeCall).then((data) => {
      if (data?.StartCallUsingContactInformationResult === true) {
        console.log(data);

        // navigate to call page
        history.push({
          pathname: '/jitsi/' + data?.portalCallContactInformation?.CallGuid,
          state: data?.portalCallContactInformation,
        });
      }
    });
    // }, 2000);
  };

  return (
    <>
      <MainContainer>
        <MainContent>
          <Content>
            <Header>
              <ButtonsContainerMenu>
                <ItensContainerMenu title={intl.formatMessage({ id: 'Back' })}>
                  <ArrowBackIcon onClick={goBack} />
                </ItensContainerMenu>
              </ButtonsContainerMenu>
              Call history
            </Header>

            <ConversationsContainer>
              {incomingCallList?.slice(0, 30).map((item, index) => (
                <div
                  className="form-control"
                  key={index}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p>
                    <strong>{item?.Caller?.Name} </strong>
                    <br></br>
                    <span style={{ color: 'red', fontSize: '12px' }}>
                      Missed Call
                    </span>
                  </p>
                  <p>
                    <img src={phoneImage} alt="" style={{ height: '25px' }} />
                  </p>
                </div>
              ))}
            </ConversationsContainer>
          </Content>
        </MainContent>
      </MainContainer>
    </>
  );
};
export default GetCallHistoryPage;
