import React, { useCallback, useRef } from 'react';
import { Sidebar, Container, Header, ItemList } from './styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import loadingIcon from '../../assets/images/loading.gif';
import {
  IconButton,
  Checkbox,
  FormControlLabel,
  Button,
} from '@material-ui/core';
import { useGroups } from '../../hooks/groups';
import { useState } from 'react';
import { GetGroupedContactInformation } from '../../services/staff';
import { useEffect } from 'react';
import {
  AddMembersToManualStaffGroup,
  CreateManualStaffGroup,
  GetOrCreateConversationByContactInformation,
} from '../../services/conversations';
import { useChatMessages } from '../../hooks/chat';
import { getUserGuid } from '../../storage';
import { ArrowForward } from '@material-ui/icons';
import { ContactsList } from '../../components/contactslist';

import { useIntl } from 'react-intl';

const NewGroup = () => {
  const { menuGroups, setMenuGroups, reloadGroups } = useGroups();
  const [isPrivate, setIsPrivate] = useState(true);
  const [persons, setPersons] = useState([]);
  const [step, setStep] = useState(0);
  const [groupName, setGroupName] = useState('');
  const [groupNameError, setGroupNameError] = useState('');
  const [contactsError, setContactsError] = useState('');
  const [personsSelected, setPersonsSeleted] = useState([]);
  const { setSelecetedConversation } = useChatMessages();
  const [loading, setLoading] = useState(false);

  const intl = useIntl();

  const reloadFields = useCallback(() => {
    setGroupName('');
    setIsPrivate(true);
    setPersonsSeleted([]);
    setStep(0);
  }, []);

  useEffect(() => {
    reloadFields();
  }, [reloadFields]);

  const selectItem = useCallback(
    (item) => {
      if (!personsSelected.find((i) => i.StaffGuid === item.StaffGuid)) {
        setPersonsSeleted([...personsSelected, item]);
      } else {
        setPersonsSeleted(
          personsSelected.filter((i) => i.StaffGuid !== item.StaffGuid)
        );
      }
    },
    [personsSelected]
  );

  const checkItem = useCallback(
    (item) => {
      return personsSelected.find((i) => i.StaffGuid === item.StaffGuid);
    },
    [personsSelected]
  );

  const handleCreateGroup = useCallback(async () => {
    if (personsSelected.length === 0) {
      setContactsError(
        intl.formatMessage({ id: 'GroupMustHaveAtLeastOneContact' })
      );
      return;
    }
    try {
      setLoading(true);
      const resStaffGroup = await CreateManualStaffGroup({
        name: groupName,
        privateGroup: !!isPrivate,
        priority: 0,
      });
      let list = personsSelected;
      list.push({
        StaffGuid: getUserGuid(),
      });
      await AddMembersToManualStaffGroup({
        staffGroup: { GroupGuid: resStaffGroup.staffGroup.GroupGuid },
        toBeIncludedMembers: personsSelected.map((item) => ({
          StaffGuid: item.StaffGuid,
        })),
      });
      const response = await GetOrCreateConversationByContactInformation({
        contactInformation: {
          Name: resStaffGroup.staffGroup.Name,
          Priority: resStaffGroup.staffGroup.Priority,
          StaffGuid: resStaffGroup.staffGroup.GroupGuid,
        },
      });
      setLoading(false);
      setSelecetedConversation({
        isGroup: true,
        ...response.conversation,
      });
      setMenuGroups(false);
      reloadFields();
      await reloadGroups();
    } catch (error) {
      setLoading(false);
    }
  }, [personsSelected, reloadFields, setMenuGroups, setSelecetedConversation]);
  const validateStep = (step) => {
    setGroupNameError('');
    if (step === 1 && !groupName) {
      setGroupNameError(intl.formatMessage({ id: 'ThisFieldIsRequired' }));
      return;
    }
    setStep(step);
  };

  useEffect(() => {
    if (groupName) {
      setGroupNameError('');
    }
  }, [groupName]);

  return (
    <Sidebar open={menuGroups}>
      <Header>
        <IconButton
          onClick={() => {
            setMenuGroups(false);
            reloadFields();
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        {intl.formatMessage({ id: 'NewGroup' })}
      </Header>
      <Container open={menuGroups}>
        {step === 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              paddingRight: 5,
              paddingTop: 5,
            }}
          >
            <ArrowForwardIcon
              onClick={() => validateStep(1)}
              style={{ color: '#039be5', cursor: 'pointer' }}
            />
          </div>
        )}
        <form>
          {step === 0 && (
            <>
              <input
                value={groupName}
                onChange={(e) => setGroupName(e.currentTarget.value)}
                placeholder={intl.formatMessage({ id: 'NameOfGroup' })}
              />
              {groupNameError && (
                <div style={{ color: 'red', fontSize: 11, padding: 5 }}>
                  {groupNameError}
                </div>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={isPrivate}
                    onChange={(e) => setIsPrivate(e.target.checked)}
                  />
                }
                label={intl.formatMessage({ id: 'Private' })}
              />
            </>
          )}
          {step === 1 && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <ArrowBackIcon
                  onClick={() => setStep(0)}
                  style={{ color: '#039be5', cursor: 'pointer' }}
                />
                {!loading && (
                  <CheckIcon
                    onClick={handleCreateGroup}
                    style={{ color: '#039be5', cursor: 'pointer' }}
                  />
                )}
                {!!loading && (
                  <img
                    src={loadingIcon}
                    alt="loading"
                    style={{ height: 20, width: 20 }}
                  />
                )}
              </div>
              {groupName && (
                <div style={{ width: '100%', textAlign: 'center' }}>
                  {/* {groupName} */}
                  {intl.formatMessage({ id: 'AddGroup' })}
                </div>
              )}
              {!!isPrivate && (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: 10,
                    marginTop: 3,
                  }}
                >
                  {/* {intl.formatMessage({ id: 'Private' })} */}
                </div>
              )}
              {!isPrivate && (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: 10,
                    marginTop: 3,
                  }}
                >
                  {/* {intl.formatMessage({ id: 'Public' })} */}
                </div>
              )}
              <ContactsList
                selectedItems={personsSelected}
                selectItem={selectItem}
              />
              {contactsError && (
                <div style={{ color: 'red', fontSize: 11, padding: 5 }}>
                  {contactsError}
                </div>
              )}
            </>
          )}
        </form>
      </Container>
    </Sidebar>
  );
};

export default NewGroup;
