const PREFIX = '@Squre';
const getInstanceId = () => {
  return localStorage.getItem(`${PREFIX}:InstanceId`);
};

const setPortalServerIp = ({ PortalServerIp }) => {
  return sessionStorage.setItem(`${PREFIX}:PortalServerIp`, PortalServerIp);
};
const getPortalServerIp = () => {
  return 'https://' + sessionStorage.getItem(`${PREFIX}:PortalServerIp`);
};
const setInstanceId = ({ InstanceId }) => {
  return localStorage.setItem(`${PREFIX}:InstanceId`, InstanceId);
};
const getConfigurationId = () => {
  return localStorage.getItem(`${PREFIX}:ConfigurationId`);
};

const setConfigurationId = ({ ConfigurationId }) => {
  return localStorage.setItem(`${PREFIX}:ConfigurationId`, ConfigurationId);
};

const getRegisterDevice = () => {
  try {
    return JSON.parse(localStorage.getItem(`${PREFIX}:RegisterDevice`));
  } catch (error) {
    return null;
  }
};

const getUserGuid = () => {
  try {
    return JSON.parse(localStorage.getItem(`${PREFIX}:RegisterDevice`))
      .RegisterDeviceResult.Configuration.UserGuid;
  } catch (error) {
    return null;
  }
};
const getUserName = () => {
  try {
    return JSON.parse(localStorage.getItem(`${PREFIX}:RegisterDevice`))
      .RegisterDeviceResult.Configuration.DisplayName;
  } catch (error) {
    return null;
  }
};

const setRegisterDevice = ({ RegisterDevice }) => {
  return localStorage.setItem(`${PREFIX}:RegisterDevice`, RegisterDevice);
};

const getIdentificationId = () => {
  return localStorage.getItem(`${PREFIX}:IdentificationId`);
};

const setIdentificationId = ({ IdentificationId }) => {
  return localStorage.setItem(`${PREFIX}:IdentificationId`, IdentificationId);
};

export {
  getConfigurationId,
  setConfigurationId,
  getInstanceId,
  setInstanceId,
  getRegisterDevice,
  setRegisterDevice,
  getIdentificationId,
  setIdentificationId,
  getUserGuid,
  getUserName,
  setPortalServerIp,
  getPortalServerIp,
};
