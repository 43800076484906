const colors = {
  background: '#039be5',
  primary: '#039be5',
  secondary: '#666360',
  light: '#f4f4f4',
  error: '#e53935',
  warning: '#fdd835',
};

export default colors;
