import React from 'react';
import { useTransition } from 'react-spring';
import { Container } from './styles';
import Toast from './toast';

const ToastContainer = ({ messages }) => {
  const messagesAnimated = useTransition(messages, (message) => message.id, {
    from: { right: '-120%' },
    enter: { right: '0%' },
    leave: { right: '-120%' },
  });
  return (
    <Container>
      {messagesAnimated.map(({ item, key, props }) => (
        <Toast key={key} message={item} style={props} />
      ))}
    </Container>
  );
};
export default ToastContainer;
