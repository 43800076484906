import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { REACT_APP_POOL_TIME_2 } from '../config';
import {
  GetConversations,
  GetMessagesForConversations,
  GetConversationsArchive,
} from '../services/conversations';
import { MarkMessageReceive } from '../services/chat';
import { getUserGuid } from '../storage';
import { useAuth } from './auth';
import { useGroups } from './groups';
import { convertBackendDateLastMessage } from '../utils';
import { useChatMessages } from './chat';
// import { getCallEvent } from '../services/conversations';
import { Alert } from '@material-ui/lab';

const ConversationContext = createContext();
const ConversationProvider = ({ children }) => {
  const { user, portal } = useAuth();
  const [conversations, setConversations] = useState([]);
  // const [getCallEvents, setCallEvents] = useState([]);
  const [archiveds, setArchiveds] = useState([]);
  const [conversation, setConversation] = useState();
  const [filterName, setFilterName] = useState('');
  const { staffGroups, reloadGroups } = useGroups();
  const [openInfo, setOpenInfo] = useState(false);
  const userGuid = getUserGuid();
  const [openChat, setOpenChat] = useState(true);
  const [coversationError, setConversationError] = useState(false);
  const reloadConversations = useCallback(async () => {
    if (user) {
      const data = await GetConversations();
      setConversations(data.conversations);
      return data.conversations;
    }
  }, [user]);

  const reloadArchivedConversations = useCallback(async () => {
    if (user) {
      const data = await GetConversationsArchive();
      setArchiveds(data.conversations);
      return data.conversations;
    }
  }, [user]);

  // const reloadGetCallEvent = useCallback(async () => {
  //   if (user) {
  //     const data = await getCallEvent();

  //     Promise.resolve(data).then((data) => {
  //       if (data?.GetCallEventResult === true) {
  //         setCallEvents(data?.callEvent);
  //       } else {
  //         setCallEvents(null);
  //       }
  //     });

  //     return data;
  //   }
  // }, [user]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (user && portal) {
        if (document.visibilityState === 'visible') {
          try {
            GetConversations().then(async (data) => {
              if (data.conversations && data.conversations.length > 0) {
                let list = [];
                for (let i = 0; i < data.conversations.length; i++) {
                  let Conversation = data.conversations[i];
                  Conversation.isGroup = !Conversation.Owner.FirstName;
                  list.push({
                    ...Conversation,
                    time: convertBackendDateLastMessage(
                      Conversation.LastMessage
                    ),
                  });
                }
                list.sort((a, b) => {
                  if (a.time > b.time) {
                    return -1;
                  }
                  if (a.time < b.time) {
                    return 1;
                  }
                  return 0;
                });
                if (list.length > 0)
                  setConversations(
                    list.filter((c) =>
                      c.Owner.Name.toUpperCase().includes(
                        filterName.toUpperCase()
                      )
                    )
                  );
                for (let i = 0; i < list.length; i++) {
                  const Conversation = list[i];
                  if (Conversation.NumberOfUnreadMessages !== 0) {
                    GetMessagesForConversations({
                      Guid: Conversation.Guid,
                      maxNumberMessages: 40,
                    }).then((data) => {
                      if (data.GetMessagesForConversationResult) {
                        data.orderedMessages.forEach(async (message) => {
                          if (
                            !message.IsDelivered &&
                            `${message.Sender.StaffGuid}` !== `${userGuid}`
                          ) {
                            await MarkMessageReceive({
                              messageGuid: message.Guid,
                            });
                          }
                        });
                      }
                    });
                  }
                }
              }
              if (data) {
                // sequential process
                GetConversationsArchive().then(async (data) => {
                  if (data.conversations && data.conversations.length > 0) {
                    let list = [];
                    for (let i = 0; i < data.conversations.length; i++) {
                      let Conversation = data.conversations[i];
                      Conversation.isGroup = !Conversation.Owner.FirstName;
                      list.push({
                        ...Conversation,
                        time: convertBackendDateLastMessage(
                          Conversation.LastMessage
                        ),
                      });
                    }
                    list.sort((a, b) => {
                      if (a.time > b.time) {
                        return -1;
                      }
                      if (a.time < b.time) {
                        return 1;
                      }
                      return 0;
                    });
                    if (list.length > 0)
                      setArchiveds(
                        list.filter((c) =>
                          c.Owner.Name.toUpperCase().includes(
                            filterName.toUpperCase()
                          )
                        )
                      );
                    for (let i = 0; i < list.length; i++) {
                      const Conversation = list[i];
                      if (Conversation.NumberOfUnreadMessages !== 0) {
                        GetMessagesForConversations({
                          Guid: Conversation.Guid,
                          maxNumberMessages: 40,
                        }).then((data) => {
                          if (data.GetMessagesForConversationResult) {
                            data.orderedMessages.forEach(async (message) => {
                              if (
                                !message.IsDelivered &&
                                `${message.Sender.StaffGuid}` !== `${userGuid}`
                              ) {
                                await MarkMessageReceive({
                                  messageGuid: message.Guid,
                                });
                              }
                            });
                          }
                        });
                      }
                    }
                  }
                });
              }
            });
            // GetConversationsArchive().then(async (data) => {
            //   if (data.conversations && data.conversations.length > 0) {
            //     let list = [];
            //     for (let i = 0; i < data.conversations.length; i++) {
            //       let Conversation = data.conversations[i];
            //       Conversation.isGroup = !Conversation.Owner.FirstName;
            //       list.push({
            //         ...Conversation,
            //         time: convertBackendDateLastMessage(
            //           Conversation.LastMessage
            //         ),
            //       });
            //     }
            //     list.sort((a, b) => {
            //       if (a.time > b.time) {
            //         return -1;
            //       }
            //       if (a.time < b.time) {
            //         return 1;
            //       }
            //       return 0;
            //     });
            //     if (list.length > 0)
            //       setArchiveds(
            //         list.filter((c) =>
            //           c.Owner.Name.toUpperCase().includes(
            //             filterName.toUpperCase()
            //           )
            //         )
            //       );
            //     for (let i = 0; i < list.length; i++) {
            //       const Conversation = list[i];
            //       if (Conversation.NumberOfUnreadMessages !== 0) {
            //         GetMessagesForConversations({
            //           Guid: Conversation.Guid,
            //           maxNumberMessages: 40,
            //         }).then((data) => {
            //           if (data.GetMessagesForConversationResult) {
            //             data.orderedMessages.forEach(async (message) => {
            //               if (
            //                 !message.IsDelivered &&
            //                 `${message.Sender.StaffGuid}` !== `${userGuid}`
            //               ) {
            //                 await MarkMessageReceive({
            //                   messageGuid: message.Guid,
            //                 });
            //               }
            //             });
            //           }
            //         });
            //       }
            //     }
            //   }
            // });
          } catch (error) {
            console.log(error);
          }
        }
      }
    }, REACT_APP_POOL_TIME_2);
    return () => clearInterval(interval);
  }, [user, portal]);

  // useEffect(() => {
  //   const interval = setInterval(async () => {
  //     if (user && portal) {
  //       if (document.visibilityState === 'visible') {
  //         try {
  //           const GET_CALL_EVENT = getCallEvent();
  //           // promise fullfilled => call is in progress
  //           Promise.resolve(GET_CALL_EVENT).then((data) => {
  //             if (data?.GetCallEventResult === true) {
  //               // set call to 1 => call is in progress
  //               setCallEvents(data?.callEvent);
  //             } else {
  //               setCallEvents(null);
  //             }
  //           });
  //         } catch (error) {
  //           console.log(error);
  //         }
  //       }
  //     }
  //   }, 10000);
  //   return () => clearInterval(interval);
  // }, [user, portal]);
  // useEffect(() => {
  //   const maxTimeOut = setTimeout(async () => {
  //     if (user && portal) {
  //       if (document.visibilityState === 'visible') {
  //         try {
  //           const GET_CALL_EVENT = getCallEvent();
  //           // promise fullfilled => call is in progress
  //           Promise.resolve(GET_CALL_EVENT).then((data) => {
  //             if (data?.GetCallEventResult === true) {
  //               // set call to 1 => call is in progress
  //               setCallEvents(data?.callEvent);
  //             } else {
  //               setCallEvents(null);
  //             }
  //           });
  //         } catch (error) {
  //           console.log(error);
  //         }
  //       }
  //     }
  //   }, 10000);
  //   return () => clearTimeout(maxTimeOut);
  // }, [user, portal]);
  return (
    <ConversationContext.Provider
      value={{
        conversations,
        archiveds,
        reloadConversations,
        reloadArchivedConversations,
        openInfo,
        setOpenInfo,
        filterName,
        setFilterName,
        openChat,
        setOpenChat,
        coversationError,
        setConversationError,
        // reloadGetCallEvent,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};

function useConversation() {
  const context = useContext(ConversationContext);
  if (!context) {
    throw new Error(
      'useConversation must be used within a ConversationProvider'
    );
  }
  return context;
}
export { ConversationProvider, ConversationContext, useConversation };
