import React, { createContext, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RegisterDevice } from '../services/auth';
import {
  getRegisterDevice,
  setIdentificationId,
  setRegisterDevice,
  setPortalServerIp,
  getPortalServerIp,
} from '../storage';
import { useToast } from './toast';
import { setPresenceStateLogin } from '../services/presenceState';

import { useIntl } from 'react-intl';

const AuthContext = createContext();
const AuthProvider = ({ children }) => {
  const intl = useIntl();

  const history = useHistory();
  const [user, setUser] = useState('');
  const [portal, setPortal] = useState(getPortalServerIp());
  const [identificationId, setIdentification] = useState('');
  const { addToast } = useToast();
  const [data, setData] = useState(() => {
    const registerDevice = getRegisterDevice();
    if (registerDevice) {
      setIdentificationId({
        IdentificationId:
          registerDevice.RegisterDeviceResult.Configuration.IdentificationId,
      });
      setRegisterDevice({
        RegisterDevice: JSON.stringify(registerDevice),
      });
      setUser(registerDevice.RegisterDeviceResult.Configuration.DisplayName);
      setIdentification(
        registerDevice.RegisterDeviceResult.Configuration.IdentificationId
      );
      setPortalServerIp({
        PortalServerIp:
          registerDevice.RegisterDeviceResult.Configuration.PortalServerIp,
      });
      setPortal(
        registerDevice.RegisterDeviceResult.Configuration.PortalServerIp
      );
      // history.push('/app');
      return { registerDevice };
    }
    return null;
  });

  const signIn = useCallback(
    async ({ userName, password, IpAddress }) => {
      try {
        const registerDevice = await RegisterDevice({
          userName,
          password,
          IpAddress,
        });
        // console.log(registerDevice);
        if (registerDevice.RegisterDeviceResult.RegistrationRequired) {
          addToast({
            title: intl.formatMessage({ id: 'AuthenticationError' }),
            description: intl.formatMessage({
              id: 'UserNotFoundCheckYourDataAndTryAgain',
            }),
            type: 'error',
          });
        } else {
          setPortalServerIp({
            PortalServerIp:
              registerDevice.RegisterDeviceResult.Configuration.PortalServerIp,
          });
          setPortal(
            registerDevice.RegisterDeviceResult.Configuration.PortalServerIp
          );
          setIdentificationId({
            IdentificationId:
              registerDevice.RegisterDeviceResult.Configuration
                .IdentificationId,
          });
          setRegisterDevice({
            RegisterDevice: JSON.stringify(registerDevice),
          });
          setUser(
            registerDevice.RegisterDeviceResult.Configuration.DisplayName
          );
          setIdentification(
            registerDevice.RegisterDeviceResult.Configuration.IdentificationId
          );
          history.push('/app');
          setPresenceStateLogin();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [addToast, history]
  );

  return (
    <AuthContext.Provider
      value={{
        registerDevice: data,
        user,
        signIn,
        identificationId,
        portal,
        setPortal,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}
export { AuthProvider, AuthContext, useAuth };
