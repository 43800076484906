import { Card } from '@material-ui/core';
import styled, { css } from 'styled-components';
const OnlineStatusProps = {
  1: css`
    background: green;
  `,
  2: css`
    background: red;
  `,
  4: css`
    background: yellow;
  `,
  8: css`
    background: #999;
  `,
};
export const Status = styled.div`
  ${(props) => OnlineStatusProps[props.status || 1]}
  width:16px;
  height: 16px;
  border-radius: 16px;
  margin-right: 10px;
`;
export const Container = styled.div`
  width: 100%;
  align-self: center;
  padding: 10px;
  input {
    width: 100%;
    background: inherit;
    border: none;
    border-bottom: 1px solid #333;
    padding: 15px 10px;
  }
`;

export const ItemList = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  padding: 5px;
  width: 100%;
  column-gap: 10px;
  font-size: 14px;
  font-weight: 400;
  justify-content: space-between;
`;
