import React, { useEffect, useRef, useState } from 'react';
import Chat from '../chat';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FaUsers, FaUser } from 'react-icons/fa';
import { MainContainer, MainContent } from '../main/styles';
import {
  Content,
  ConversationsContainer,
  ConversationItem,
  Header,
  Profile,
  HeaderChat,
  Status,
  DateTimeMessage,
  ContactContainer,
  Reads,
  ButtonsContainerMenu,
  SearchInput,
  SearchArea,
  ItensContainerMenu,
} from './styles';
import { useConversation } from '../../hooks/conversation';
import { useChatMessages } from '../../hooks/chat';
import ListContacts from '../../components/contacts';
import NewGroup from '../../components/newgroup';
import { convertBackendDate, getStringDate, truncateString } from '../../utils';
import MenuConversation from '../../components/menuConversation';
import { useHistory } from 'react-router-dom';
import Info from '../../components/info';
import { useIntl } from 'react-intl';

const Archiveds = () => {
  const { archiveds, filterName, setFilterName, openInfo, setOpenInfo } =
    useConversation();
  const history = useHistory();
  const {
    conversation,
    setSelecetedConversation,
    setMessages,
    clearConversation,
    setSelectedPage,
    openChat,
    setOpenChat,
  } = useChatMessages();
  const [dataFiltered, setDataFiltered] = useState([]);
  const inputRef = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    if (archiveds) {
      // console.log(conversations[0]?.Owner.Name.toUpperCase());
      setDataFiltered(
        archiveds.filter((c) =>
          c.Owner.Name.toUpperCase().includes(filterName.toUpperCase())
        )
      );
      setSelectedPage(true);
    }
  }, [archiveds]);

  const goBack = () => {
    clearConversation('');
    setSelectedPage(false);
    if (openInfo) setOpenInfo(false);
    history.goBack();
  };

  const onKeyUp = (event) => {
    setFilterName(inputRef.current?.value);
  };

  return (
    <>
      <MainContainer>
        <MainContent>
          <Content>
            <Header>
              <ButtonsContainerMenu>
                <ItensContainerMenu title={intl.formatMessage({ id: 'Back' })}>
                  <ArrowBackIcon onClick={goBack} />
                </ItensContainerMenu>
              </ButtonsContainerMenu>
              {/* <Profile>{user}</Profile> */}
              {intl.formatMessage({ id: 'Archiveds' })}
            </Header>
            <SearchArea>
              <SearchInput
                ref={inputRef}
                onKeyUp={onKeyUp}
                placeholder={intl.formatMessage({ id: 'SearchHere' })}
              />
            </SearchArea>

            <ConversationsContainer>
              {dataFiltered &&
                dataFiltered.length > 0 &&
                dataFiltered.map((c, key) => {
                  let dateString = '';
                  if (c.LastMessage) {
                    dateString = convertBackendDate(
                      c.LastMessage.MessageDateTime
                    );
                  }
                  return (
                    <ConversationItem
                      key={key}
                      onClick={() => {
                        setSelecetedConversation(c);
                        setOpenInfo(true);
                        setOpenChat(false);
                        setMessages([]);
                      }}
                      isSelected={conversation && conversation.Guid === c.Guid}
                    >
                      {c.NumberOfUnreadMessages !== 0 && (
                        <Reads>{c.NumberOfUnreadMessages}</Reads>
                      )}
                      <ContactContainer>
                        <div style={{ display: 'flex', columnGap: 5 }}>
                          {!c.isGroup && <FaUser />}
                          {c.isGroup && <FaUsers />}
                          <span title={c.Owner.Name}>
                            {truncateString(c.Owner.Name, 60)}
                          </span>
                        </div>
                        {c.isGroup && c.LastMessage && (
                          <h6
                            title={
                              c.LastMessage.ReferencedMessage &&
                              c.IsForward === true
                                ? c.LastMessage.ReferencedMessage.Message
                                : c.LastMessage.Message
                            }
                          >
                            {truncateString(
                              `${c.LastMessage.Sender.Name}: ${
                                c.LastMessage.ReferencedMessage &&
                                c.IsForward === true
                                  ? c.LastMessage.ReferencedMessage.Message
                                  : c.LastMessage.Message
                              }`,
                              60
                            )}
                          </h6>
                        )}
                        {!c.isGroup && c.LastMessage && (
                          <h6
                            title={
                              c.LastMessage.ReferencedMessage &&
                              c.IsForward === true
                                ? c.LastMessage.ReferencedMessage.Message
                                : c.LastMessage.Message
                            }
                          >
                            {truncateString(
                              c.LastMessage.ReferencedMessage &&
                                c.IsForward === true
                                ? c.LastMessage.ReferencedMessage.Message
                                : c.LastMessage.Message,
                              60
                            )}
                          </h6>
                        )}
                      </ContactContainer>
                      <DateTimeMessage
                        unreadLabel={c.NumberOfUnreadMessages !== 0}
                      >
                        {getStringDate(dateString)}
                      </DateTimeMessage>
                    </ConversationItem>
                  );
                })}
            </ConversationsContainer>
            {conversation && openChat && (
              <React.Fragment>
                <HeaderChat>
                  <div>
                    <Profile>
                      {!conversation.isGroup && !conversation.Owner.Name && (
                        <Status status={conversation.Owner.OnlineState} />
                      )}
                      {conversation.Owner?.Name}
                    </Profile>
                  </div>
                  <MenuConversation />
                </HeaderChat>
                <Chat conversationId={conversation} />
              </React.Fragment>
            )}
            {openInfo && <Info />}

            <ListContacts clearFilterChats={() => setFilterName('')} />
            <NewGroup clearFilterChats={() => setFilterName('')} />
          </Content>
        </MainContent>
      </MainContainer>
    </>
  );
};
export default Archiveds;
