import React, { createContext, useContext, useState } from 'react';

const ContactsContext = createContext();
const ContactsProvider = ({ children }) => {
  const [contacts, setContacts] = useState([]);
  const [menu, setMenu] = useState(false);

  return (
    <ContactsContext.Provider value={{ contacts, setContacts, menu, setMenu }}>
      {children}
    </ContactsContext.Provider>
  );
};

function useContacts() {
  const context = useContext(ContactsContext);
  if (!context) {
    throw new Error('useContacts must be used within a AppProvider');
  }
  return context;
}
export { ContactsProvider, ContactsContext, useContacts };
