import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Image = ({ att, setAttSeleted, urlDowload }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
  }, []);
  const onLoad = () => {
    setLoading(false);
  };

  return (
    <>
      <img
        style={{ opacity: loading === false ? 1 : 0 }}
        alt={att.Name}
        src={`${urlDowload}/${att.Guid}`}
        onClick={() => setAttSeleted(att)}
        onLoad={onLoad}
      />
      {!!loading && <CircularProgress color="secondary" size={20} />}
    </>
  );
};

export { Image };
