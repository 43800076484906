import { FunctionComponent, useEffect, useState } from 'react';
import React from 'react';
import { Jutsu } from 'react-jutsu';
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
// import './externalAPI.js';
import './styles.css';
import callImg from './molumen_phone_icon.png';
import { callDisconnectUsingGuid } from '../../services/conversations';

export default function JitsiCall() {
  const [room, setRoom] = useState('');
  const [name, setName] = useState('');
  const [call, setCall] = useState(false);
  // const [conversations, setConversations] = useState([]);

  // location props
  const location = useLocation();

  useEffect(() => {
    let url = window.location.href;
    const strs = url.split('/');
    const roomID = strs.at(-1);
    // console.log(roomID);
    // const data = GetConversations();

    // setConversations(data?.conversations);
    // console.log(conversations);

    // if (location?.state?.callerData) {
    setName(
      location?.state?.callerData?.Name ? location?.state?.callerData?.Name : ''
    );
    setRoom(
      location?.state?.callerData?.CallGuid
        ? location?.state?.callerData?.CallGuid
        : roomID
    );
    setCall(true);
  }, []);

  // end call
  const endCall = () => {
    // call disconnect API call
    let url = window.location.href;
    const strs = url.split('/');
    const roomID = strs.at(-1);
    const disconnectCall = callDisconnectUsingGuid(roomID);

    Promise.resolve(disconnectCall).then((data) => {
      if (data?.CallCancelledResult === true) {
        // set call to false
        setCall(false);
        // redirect to conversations page
        window.location.href = '/app';
      } else {
        // reload
        window.location.reload();
      }
    });
  };

  return (
    call && (
      <>
        <button className="float-end" onClick={endCall}>
          <img src={callImg} alt="call" height={'20px'} />
          <span style={{ marginLeft: '5px' }}>End Call</span>
        </button>
        <Jutsu
          loadingComponent={<CircularProgress />}
          containerStyles={{ width: '100%', height: '100vh' }}
          domain="talk.squre.io/herton" // => change to server URL
          roomName={room}
          displayName={name}
          onMeetingEnd={() => {
            window.location.replace('/app');
          }}
          interfaceConfigOverwrite={{
            ENABLE_WELCOME_PAGE: false,
            SHOW_JITSI_WATERMARK: false,
            SHOW_WATERMARK_FOR_GUESTS: false,
            DISPLAY_WELCOME_PAGE_CONTENT: false,
            SHOW_CHROME_EXTENSION_BANNER: false,
            SHOW_POWERED_BY: false,
            SHOW_PROMOTIONAL_CLOSE_PAGE: true,
            HIDE_DEEP_LINKING_LOGO: true,
            SHOW_BRAND_WATERMARK: false,
            DEFAULT_LOGO_URL: '../../assets/images/logo.png',
            DEFAULT_WELCOME_PAGE_LOGO_URL: '../../assets/images/logo.png',
            ENABLE_DIAL_OUT: true,
          }}
          configOverwrite={{
            disableSimulcast: false,
            enableClosePage: true,
            defaultLanguage: 'en',
            logoImageUrl: '../../assets/images/logo.png',
          }}
          errorComponent={<p>Oops, something went wrong</p>}
        />
      </>
    )
  );
}
