import React, { createContext, useCallback, useContext, useState } from 'react';
const LoadingContext = createContext();
const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const startLoading = useCallback(() => {
    setLoading(true);
  }, []);
  const stopLoading = useCallback(() => {
    setLoading(false);
  }, []);
  return (
    <LoadingContext.Provider value={{ loading, startLoading, stopLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
}
export { LoadingProvider, LoadingContext, useLoading };
