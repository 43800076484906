import subscribeUserToPush, { requestPermission } from '../swRegistration';
import api from './api';
import { getInstance } from './util';

const RegisterDevice = async ({ userName, password, IpAddress }) => {
  return api
    .post('/DiscoveryClient/ios/RegisterDevice', {
      request: getInstance({ IpAddress }),
      userName,
      password,
    })
    .then((res) => {
      subscribeUserToPush();
      return res.data;
    });
};
export { RegisterDevice };
