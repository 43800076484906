import { getIdentificationId, getPortalServerIp } from '../storage';
import api from './apiPortal';
import { IdentificationId } from './util';

const SendMessageUsingContactInformation = async ({
  contactInformation,
  Attachments = null,
  Message,
  ParentMessageGuid = [],
}) => {
  let body = {};
  api.defaults.baseURL = getPortalServerIp();
  try {
    const staffMessage = {
      Attachments,
      Message,
      ParentMessageGuid,
      MessageDateTime: `/Date(${Date.now()})/`,
    };
    body = IdentificationId();
    body.contactInformation = contactInformation;
    body.staffMessage = staffMessage;
  } catch (error) {
    console.log(error);
  }
  return api
    .post('/PortalStaff/ios/SendMessageUsingContactInformation', body)
    .then((res) => res.data);
};

const SendMessageUsingFilter = async ({
  conversationGuid,
  Attachments = [],
  Message,
  ParentMessageGuid = [],
}) => {
  api.defaults.baseURL = getPortalServerIp();
  const staffMessage = {
    Attachments,
    Message,
    ParentMessageGuid,
    MessageDateTime: `/Date(${Date.now()})/`,
  };
  let contactInformationFilter = {
    IncludedStaffGroupGuids: [conversationGuid],
    IncludedOnlineStates: [1, 2, 4, 8],
  };
  let data = {};
  data = IdentificationId();
  data.contactInformationFilter = contactInformationFilter;
  data.staffMessage = staffMessage;
  data.broadcastMessage = true;
  return api
    .post('/PortalStaff/ios/SendMessageUsingFilter', data)
    .then((res) => res.data);
};

const ForwardMessageUsingContactInformation = async ({
  contactInformation,
  attachments = null,
  messageGuid,
  messageText,
}) => {
  api.defaults.baseURL = getPortalServerIp();
  let body = {};
  try {
    body = IdentificationId();
    body.contactInformation = contactInformation;
    body.messageGuid = messageGuid;
    body.messageText = messageText;
    body.attachments = attachments;
  } catch (error) {
    console.log(error);
  }
  return api
    .post('/PortalStaff/ios/ForwardMessageUsingContactInformation', body)
    .then((res) => res.data);
};
const ForwardMessageUsingFilter = async ({
  contactInformation,
  attachments = null,
  messageGuid,
  messageText,
}) => {
  api.defaults.baseURL = getPortalServerIp();
  let body = {};
  try {
    body = IdentificationId();
    let contactInformationFilter = {
      IncludedStaffGroupGuids: [contactInformation.StaffGuid],
      IncludedOnlineStates: [1, 2, 4, 8],
    };
    body.contactInformationFilter = contactInformationFilter;
    body.messageGuid = messageGuid;
    body.messageText = messageText;
    body.attachments = attachments;
    body.broadcastMessage = true;
  } catch (error) {
    console.log(error);
  }
  return api
    .post('/PortalStaff/ios/ForwardMessageUsingFilter', body)
    .then((res) => res.data);
};

const MarkMessageRead = async ({ messageGuid }) => {
  let data = {};
  data = IdentificationId();
  data.messageGuid = messageGuid;
  return api
    .post('/PortalStaff/ios/MarkMessageRead', data)
    .then((res) => res.data);
};

const MarkMessageReceive = async ({ messageGuid }) => {
  let data = {};
  api.defaults.baseURL = getPortalServerIp();
  data = IdentificationId();
  data.messageGuid = messageGuid;
  return api
    .post('/PortalStaff/ios/MarkMessageReceived', data)
    .then((res) => res.data);
};
const CreateAttachment = async ({ attachmentType, attachmentName }) => {
  let data = {};
  api.defaults.baseURL = getPortalServerIp();
  if (`${attachmentType}` === 'image/png') {
    attachmentType = 0;
  }
  if (`${attachmentType}` === 'image/jpeg' || attachmentType === 'image/jpg') {
    attachmentType = 1;
  }
  if (`${attachmentType}` === 'application/pdf') {
    attachmentType = 10;
  }
  if (`${attachmentType}` === 'plain/text') {
    attachmentType = 10;
  }
  if (`${attachmentType}` === 'video/mp4') {
    attachmentType = 20;
  }
  try {
    let test = Number(attachmentType);
  } catch (error) {
    attachmentType = 100;
  }
  data = IdentificationId();
  data.attachmentType = attachmentType;
  data.attachmentName = attachmentName;
  return api
    .post('/PortalStaff/ios/CreateAttachment', data)
    .then((res) => res.data);
};
const UploadToAws = async ({ file, attachmentGuid }) => {
  api.defaults.baseURL = getPortalServerIp();
  return api
    .post(
      `/CareServantAttachments/AttachmentManagerService.svc/UploadAttachment/${getIdentificationId()}/${attachmentGuid}`,
      file,
      {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      }
    )
    .then((res) => res.data);
};
const Download = async ({ attachmentGuid }) => {
  api.defaults.baseURL = getPortalServerIp();
  return api
    .get(
      `/CareServantAttachments/AttachmentManagerService.svc/DownloadAttachment/${getIdentificationId()}/${attachmentGuid}`
    )
    .then((res) => res.data);
};

const ReplyMessage = async ({ messageGuid, reply }) => {
  api.defaults.baseURL = getPortalServerIp();
  let data = {};
  data = IdentificationId();
  data.messageGuid = messageGuid;
  data.reply = reply;
  return api
    .post('/PortalStaff/ios/ReplyToMessage', data)
    .then((res) => res.data);
};

const RemoveMessage = async ({ staffMessage }) => {
  api.defaults.baseURL = getPortalServerIp();
  let data = {};
  data = IdentificationId();
  data.staffMessage = staffMessage;
  return api.post('/PortalStaff/ios/RemoveMessage', data).then((res) => {
    console.log(res.data);
    return res.data;
  });
};

export {
  SendMessageUsingContactInformation,
  SendMessageUsingFilter,
  MarkMessageRead,
  MarkMessageReceive,
  ReplyMessage,
  RemoveMessage,
  CreateAttachment,
  UploadToAws,
  ForwardMessageUsingContactInformation,
  ForwardMessageUsingFilter,
  Download,
};
