import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useCallback, useEffect, useState } from 'react';
import { Body, Header, HeaderTitle, ItemList, Title } from './styles';
import { useGroups } from '../../hooks/groups';
import { GetGroupedContactInformation } from '../../services/staff';
import CheckIcon from '@material-ui/icons/Check';
import {
  ForwardMessageUsingContactInformation,
  ForwardMessageUsingFilter,
} from '../../services/chat';
import { giveNames, sortList } from '../../utils';

import { useIntl } from 'react-intl';

const Forward = ({ message, open, handleClose }) => {
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const { staffGroups } = useGroups();

  const intl = useIntl();

  useEffect(() => {
    const init = async () => {
      if (message && contacts.length === 0) {
        const response = await GetGroupedContactInformation();
        if (response.GetGroupedContactInformationResult) {
          let list = [];
          response.staffContactInformationList.forEach((contact) => {
            let names = giveNames(contact.Name);
            list.push({
              ...contact,
              LastName: names.LastName,
              Name: names.Name,
              isGroup: false,
            });
          });
          staffGroups.forEach((group) => {
            if (!list.some((s) => s.StaffGuid === group.GroupGuid))
              list.push({
                StaffGuid: group.GroupGuid,
                Name: group.Name,
                LastName: group.Name,
                Priority: group.Priority,
                isGroup: true,
              });
          });
          sortList(list);
          setContacts(list);
        }
      }
    };
    init();
  }, [contacts.length, message, staffGroups]);

  const close = useCallback(() => {
    setContacts([]);
    setSelectedContacts([]);
    handleClose();
  }, [handleClose]);
  const selectItem = useCallback(
    (item) => {
      if (!selectedContacts.find((i) => i.StaffGuid === item.StaffGuid)) {
        setSelectedContacts([...selectedContacts, item]);
      } else {
        setSelectedContacts(
          selectedContacts.filter((i) => i.StaffGuid !== item.StaffGuid)
        );
      }
    },
    [selectedContacts]
  );

  const checkItem = useCallback(
    (item) => {
      return selectedContacts.find((i) => i.StaffGuid === item.StaffGuid);
    },
    [selectedContacts]
  );

  const forwardMessageUsingContactInformation = async () => {
    for (let i = 0; i < selectedContacts.length; i++) {
      const contact = selectedContacts[i];
      if (contact.isGroup === true) {
        await ForwardMessageUsingFilter({
          contactInformation: contact,
          messageGuid: message.ReferencedMessage
            ? message.ReferencedMessage.Guid
            : message.Guid,
          messageText: '',
          attachments: message.Attachments,
        });
      } else {
        await ForwardMessageUsingContactInformation({
          contactInformation: contact,
          messageGuid: message.ReferencedMessage
            ? message.ReferencedMessage.Guid
            : message.Guid,
          messageText: '',
          attachments: message.Attachments,
        });
      }
    }
    close();
  };
  return (
    <Dialog open={open} onClose={close} maxWidth={'sm'}>
      <Header>
        <CloseIcon onClick={close} />
        <HeaderTitle>
          {intl.formatMessage({ id: 'ForwardMessageTo' })}
        </HeaderTitle>
      </Header>
      <DialogContent>
        <Body>
          {contacts.map((item, key) => {
            return (
              <ItemList key={key} onClick={() => selectItem(item)}>
                {!item.isGroup && (
                  <div>
                    {`${item.Name} `}
                    <span style={{ fontWeight: 'bolder' }}>
                      {item.LastName}
                    </span>
                  </div>
                )}
                {item.isGroup && <div>{`${item.Name}`}</div>}
                {checkItem(item) && <CheckIcon style={{ fontSize: 14 }} />}
              </ItemList>
            );
          })}
        </Body>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={close} color="primary">
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button
          onClick={forwardMessageUsingContactInformation}
          color="secondary"
        >
          {intl.formatMessage({ id: 'Forward' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Forward;
