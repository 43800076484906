/* eslint-disable prettier/prettier */
import React, { useCallback, useState } from 'react';
import {
  Sidebar,
  Container,
  Header,
  IconContainer,
  InfoContainer,
  MembersContainer,
  NameContainer,
  RemoveContainer,
} from './styles';
import CloseIcon from '@material-ui/icons/Close';
import AddBoxIcon from '@material-ui/icons/AddBox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Icon, InlineIcon } from '@iconify/react';
import squareEditOutline from '@iconify-icons/mdi/square-edit-outline';
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@material-ui/core';
import { useConversation } from '../../hooks/conversation';
import { useChatMessages } from '../../hooks/chat';
import { FaUser, FaUsers } from 'react-icons/fa';
import { useEffect } from 'react';
import {
  GetStaffInformationForStaffGroup,
  UpdateManualStaffGroup,
} from '../../services/staff';
import Contacts from './contacts';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import { DeleteManualStaffGroup, GetOrCreateConversationByContactInformation } from '../../services/conversations';
import ConfirmDialog from '../confirmdialog';
import Members from './members';
import { useGroups } from '../../hooks/groups';
import { useHistory } from 'react-router-dom';

import { useToast } from '../../hooks/toast';

import { useIntl } from 'react-intl';

const Info = () => {
  const intl = useIntl();

  const { openInfo,setOpenInfo,openChat,setOpenChat,conversations} = useConversation();
  const { reloadGroups } = useGroups();
  const [editName, setEditName] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openContacts, setOpenContacts] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [name, setName] = useState('');
  const [GroupGuid, setGroupGuid] = useState();
  const [isPrivate, setIsPrivate] = useState(true);
  const [membersGroupList, setMembersGroupList] = useState([]);
  // prettier-ignore
  const {
    group,
    clearConversation,
    selectedPage,
    setSelectedPage,
    createGroupConversation,
  } = useChatMessages();
  const [data, setData] = useState();
  // prettier-ignore
  const {
    setSelectedGroup,
    setSelecetedConversation,
    deleteConversation,
  } = useChatMessages();
  const { conversation } = useChatMessages();
  const history = useHistory();

  const { addToast } = useToast(false);

  useEffect(() => {
    const init = async () => {
      setGroupGuid(group?.GroupGuid);
      setEditName(false);
      const response = await GetStaffInformationForStaffGroup({
        staffGroupGuid: group?.GroupGuid,
      });
      if (response.GetStaffInformationForStaffGroupResult) {
        setData(response.staffGroup);
        setCanEdit(group.Administrator);
        setIsPrivate(response.staffGroup.PrivateGroup);
        setMembersGroupList(response.staffContactInformationList);
      } else {
        setCanEdit(false);
      }
      setName(group?.Name);
    };
    if (group) init();
  }, [group]);
  const reload = async () => {
    setOpenContacts(false);

    setGroupGuid('');
    const response = await GetStaffInformationForStaffGroup({
      staffGroupGuid: group?.GroupGuid,
    });

    setGroupGuid(group?.GroupGuid);
    if (response.GetStaffInformationForStaffGroupResult) {
      setData(response.staffGroup);
      setCanEdit(group.Administrator);
      setIsPrivate(response.staffGroup.PrivateGroup);
    }
  };
  const editStaffGroup = useCallback(
    async (privateGroup = null) => {
      if (privateGroup !== null) {
        setIsPrivate(privateGroup);
      }
      let sg = data;
      sg.Name = name;
      sg.PrivateGroup = privateGroup !== null ? privateGroup : isPrivate;
      const response = await UpdateManualStaffGroup({
        staffGroup: sg,
      });
      setData(response.staffGroup);
      setSelecetedConversation(
        {
          ...conversation,
          Owner: {
            ...conversation.Owner,
            Name: name,
          },
        },
        true
      );
      setEditName(false);
      setCanEdit(true);
      await reloadGroups();
    },
    [name, data]
  );

  const cancelEditStaffGroup = useCallback(() => {
    setName(data.Name);
    setEditName(false);
  }, [name, group]);

  const deleteStaffGroup = useCallback(async () => {
    await deleteConversation({ data });
    setOpenInfo(false);
    setOpenConfirm(false);
    await reloadGroups();
  }, [name, group]);

  const handleClickMessages = async (event) => {
    if (
      !conversations.some(
        (conver) => conver.Owner.StaffGuid === group.GroupGuid
      )
      // && membersGroupList.length === 0
      )
      {
      addToast({
        title: intl.formatMessage({
          id: 'YouHaveNoRightsToWriteAGroupMessageIntoThisGroup',
        }),
        type: 'error',
      });
      return;
    } else {
      // createGroupConversation(group);
      setSelectedPage(false);
      setOpenChat(true);
      setOpenInfo(false);

      history.push('/app');
    }
  };


  return (
    <Sidebar open={true}>
      <Header open={true}>
        <IconButton
          title={intl.formatMessage({
            id: 'NewGroupMessage',
          })}
          onClick={async () => {
            handleClickMessages();
          }}
        >
          <Icon icon={squareEditOutline} width="24px" height="24px" />
        </IconButton>
        {intl.formatMessage({
          id: 'GroupData',
        })}
        <IconButton
          title={intl.formatMessage({
            id: 'Close',
          })}
          onClick={() => setOpenInfo(false)}
        >
          <CloseIcon />
        </IconButton>
      </Header>
      <Container open={true}>
        <InfoContainer>
          <IconContainer>
            <FaUsers />
          </IconContainer>

          <NameContainer>
            {!canEdit && <span>{name ? name : ''}</span>}
            {canEdit && !editName && <span>{name ? name : ''}</span>}
            {canEdit && editName && (
              <input
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                type="text"
              />
            )}
            {canEdit && !editName && group && (
              <EditIcon
                title={intl.formatMessage({
                  id: 'EditName',
                })}
                onClick={() => setEditName(true)}
              />
            )}
            {canEdit && editName && group && (
              <CheckIcon
                title={intl.formatMessage({
                  id: 'Ok',
                })}
                onClick={() => editStaffGroup()}
              />
            )}
            {canEdit && editName && group && (
              <CloseIcon
                title={intl.formatMessage({
                  id: 'Cancel',
                })}
                onClick={() => cancelEditStaffGroup()}
              />
            )}
          </NameContainer>
          {canEdit && (
            <FormControlLabel
              style={{
                alignSelf: 'flex-start',
              }}
              control={
                <Checkbox
                  style={{ paddingRight: 2 }}
                  size="small"
                  color="secondary"
                  checked={isPrivate}
                  onChange={(e) => editStaffGroup(e.target.checked)}
                />
              }
              label={
                <label style={{ fontSize: 12 }}>
                  {intl.formatMessage({
                    id: 'Private',
                  })}
                </label>
              }
            />
          )}
          <h6>
            {!canEdit && data && data.PrivateGroup === true
              ? intl.formatMessage({
                  id: 'Private',
                })
              : null}
          </h6>
          {canEdit && (
            <RemoveContainer>
              <IconButton
                title={intl.formatMessage({
                  id: 'DeleteGroup',
                })}
                onClick={() => setOpenConfirm(true)}
              >
                <DeleteIcon />
              </IconButton>
            </RemoveContainer>
          )}
        </InfoContainer>

        <MembersContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>{intl.formatMessage({ id: 'Members' })}</span>
            {canEdit && (
              <IconButton
                title={intl.formatMessage({
                  id: 'AddNewMember',
                })}
                onClick={() => setOpenContacts(true)}
              >
                <AddBoxIcon />
              </IconButton>
            )}
          </div>

          <Members GroupGuid={GroupGuid} reload={reload} canEdit={canEdit} />
        </MembersContainer>
      </Container>
      <ConfirmDialog
        handleCancel={() => setOpenConfirm(false)}
        handleOk={deleteStaffGroup}
        question={intl.formatMessage({
          id: 'DoYouWantToDeleteThisGroup?',
        })}
        open={openConfirm}
      />
      <Contacts
        open={openContacts}
        GroupGuid={GroupGuid}
        handleClose={() => setOpenContacts(false)}
        reload={reload}
      />
    </Sidebar>
  );
};

export default Info;
