/* eslint-disable prettier/prettier */
import { IconButton, MenuItem } from '@material-ui/core';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Chat from '../chat';

import ChatIcon from '@material-ui/icons/Chat';
import GroupIcon from '@material-ui/icons/Forum';
import ContactsIcon from '@material-ui/icons/Contacts';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { FaUsers, FaSignOutAlt, FaPlusSquare, FaGlobe } from 'react-icons/fa';
import { Icon, InlineIcon } from '@iconify/react';
import squareEditOutline from '@iconify-icons/mdi/square-edit-outline';
import { MainContainer, MainContent } from '../main/styles';
import {
  Content,
  ConversationsContainer,
  ConversationItem,
  Header,
  Profile,
  HeaderChat,
  ButtonsContainer,
  DateTimeMessage,
  ContactContainer,
  Reads,
  ButtonsContainerMenu,
  SearchInput,
  SearchArea,
  ItensContainerMenu,
} from './styles';
import { useConversation } from '../../hooks/conversation';
import { useAuth } from '../../hooks/auth';
import { useChatMessages } from '../../hooks/chat';
import ListContacts from '../../components/contacts';
import { useContacts } from '../../hooks/contacts';
import MenuSettings from '../../components/menuSettings';
import NewGroup from '../../components/newgroup';
import { convertBackendDate, getStringDate, truncateString } from '../../utils';
import MenuConversation from '../../components/menuConversation';
import { useGroups } from '../../hooks/groups';
import { useHistory } from 'react-router-dom';
import Info from '../../components/infoGroup';
import { useIntl } from 'react-intl';
import ChangeLanguage from '../../components/ChangeLanguage';

const Groups = () => {
  const { user, setPortal } = useAuth();
  // prettier-ignore
  const {
    conversations,
    filterName,
    setFilterName,
    openInfo,
    setOpenInfo,
  } = useConversation();
  const { reloadGroups, setMenuGroups, staffGroups } = useGroups();
  const history = useHistory();
  const {
    conversation,
    setSelecetedConversation,
    setMessages,
    setSelectedGroup,
    group,
    clearConversation,
    selectedPage,
    setSelectedPage,
  } = useChatMessages();
  const [dataFiltered, setDataFiltered] = useState([]);
  const [dataSorted, setDataSorted] = useState([]);

  const intl = useIntl();

  const inputRef = useRef(null);
  const { setMenu } = useContacts();
  // prettier-ignore
  const {
    openChat,
    setOpenChat,
    coversationError,
    setConversationError,
  } = useConversation();
  const [groupId, setGroupId] = useState('');

  useEffect(() => {
    if (conversations) {
      //console.log(conversations[0]?.Owner.Name.toUpperCase());
      setDataFiltered(
        staffGroups.filter((c) =>
          c.Name.toUpperCase().includes(filterName.toUpperCase())
        )
      );

      const compare = (a, b) => {
        if (a.Name < b.Name) {
          return -1;
        }
        if (a.Name > b.Name) {
          return 1;
        }
        return 0;
      };
      setDataSorted(dataFiltered.sort(compare));
      setSelectedPage(true);
    }
  }, [conversations]);

  useEffect(() => {
      console.log(conversations)
      conversations.forEach((c) => {
      if (c.Owner.StaffGuid === groupId) {
        setSelecetedConversation(c);
      }
    });
  }, [groupId]);

  const onKeyUp = (event) => {
    setFilterName(inputRef.current?.value);
  };

  const handleClickContacts = (event) => {
    history.push('/contacts');
  };
  const handleClickMessages = (event) => {
    setSelectedPage(false);
    setOpenChat(true);
    setOpenInfo(false);
    clearConversation('');
    history.push('/app');
  };
  const handleClickItem = () => {
    setOpenChat(true);
  };

  const logout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    setPortal(null);
    history.push('/');
  }, [history, setPortal]);

  return (
    <>
      <MainContainer>
        <MainContent>
          <Content>
            <Header>
              <ButtonsContainerMenu>
                <ItensContainerMenu
                  title={intl.formatMessage({
                    id: 'Contacts',
                  })}
                  isSelected={selectedPage}
                >
                  <ContactsIcon onClick={handleClickContacts} />
                </ItensContainerMenu>
                <ItensContainerMenu
                  title={intl.formatMessage({ id: 'NewMessage' })}
                >
                  <ChatIcon onClick={handleClickMessages} />
                </ItensContainerMenu>
                <ItensContainerMenu
                  title={intl.formatMessage({ id: 'Logout' })}
                >
                  <FaSignOutAlt size={22} onClick={logout} />
                </ItensContainerMenu>
              </ButtonsContainerMenu>
              {/* <Profile>{user}</Profile> */}
              {intl.formatMessage({id: 'Contacts'})}
              <ButtonsContainerMenu>
                <ItensContainerMenu title={intl.formatMessage({ id: 'Idioms' })}>
                <ChangeLanguage space='0.3rem' />
              </ItensContainerMenu>
            </ButtonsContainerMenu>
            </Header>
            <SearchArea>
              <SearchInput
                ref={inputRef}
                onKeyUp={onKeyUp}
                placeholder={intl.formatMessage({ id: 'SearchHere' })}
              />
              <FaPlusSquare
                color="#666666"
                title={intl.formatMessage({ id: 'NewGroup' })}
                onClick={async () => {
                  setMenuGroups(true);
                  setOpenInfo(false);
                }}
              />
            </SearchArea>

            <ConversationsContainer>
              {dataSorted &&
                dataSorted.length > 0 &&
                dataSorted.map((g, key) => {
                  return (
                    <ConversationItem
                      key={key}
                      onClick={() => {
                        setOpenInfo(true);
                        setOpenChat(false);
                        setSelectedGroup(g);
                        setGroupId(g.GroupGuid);
                      }}
                      isSelected={group && group.GroupGuid === g.GroupGuid}
                    >
                      <ContactContainer>
                        <div style={{ display: 'flex', columnGap: 5 }}>
                          <FaUsers />
                          <span title={g.Name}>
                            {truncateString(g.Name, 60)}
                          </span>
                        </div>
                      </ContactContainer>
                    </ConversationItem>
                  );
                })}
            </ConversationsContainer>
            {openInfo && <Info />}

            <ListContacts clearFilterChats={() => setFilterName('')} />
            <NewGroup clearFilterChats={() => setFilterName('')} />
          </Content>
        </MainContent>
      </MainContainer>
    </>
  );
};
export default Groups;
