import React, { useEffect, useState } from 'react';
import { SearchUserDirectory } from '../../services/staff';
import { Sidebar, Container, Header, ItemList, ItemSelected } from './styles';

import { useIntl } from 'react-intl';

const ContactsList = ({ selectedItems = [], selectItem }) => {
  const [filter, setFilter] = useState('');
  const [list, setList] = useState([]);

  const intl = useIntl();

  useEffect(() => {
    setList([]);
  }, []);

  useEffect(() => {
    const load = async () => {
      setList(await SearchUserDirectory({ searchString: filter }));
    };
    if (filter.length >= 3) {
      load();
    } else {
      setList([]);
    }
  }, [filter]);

  return (
    <div style={{ width: '100%', overflowY: 'auto' }}>
      <input
        style={{
          width: '100%',
          background: 'inherit',
          border: 0,
          padding: 10,
          borderBottom: '1px solid #333',
        }}
        placeholder={intl.formatMessage({ id: 'SearchForContactsHere' })}
        value={filter}
        onChange={(e) => setFilter(e.currentTarget.value)}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 10,
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        {selectedItems.map((item, key) => {
          return (
            <ItemSelected key={key} onClick={() => selectItem(item)}>
              {item.FirstName} {item.LastName}
            </ItemSelected>
          );
        })}
      </div>
      {list.map((item, key) => {
        return (
          <ItemList key={key} onClick={() => selectItem(item)}>
            {!item.isGroup && (
              <div>
                {`${item.FirstName} `}
                <span style={{ fontWeight: 'bolder' }}>{item.LastName}</span>
              </div>
            )}
            {item.isGroup && <div>{`${item.Name}`}</div>}
          </ItemList>
        );
      })}
    </div>
  );
};
export { ContactsList };
