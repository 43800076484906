import React, { createContext, useContext, useState } from 'react';
const MainContext = createContext();
const MainProvider = ({ children }) => {
  const [page, setPage] = useState(1);
  return (
    <MainContext.Provider value={{ page, setPage }}>
      {children}
    </MainContext.Provider>
  );
};

function useMain() {
  const context = useContext(MainContext);
  if (!context) {
    throw new Error('useMain must be used within a MainProvider');
  }
  return context;
}
export { MainProvider, MainContext, useMain };
