import { getPortalServerIp } from '../storage';
import api from './apiPortal';
import { IdentificationId } from './util';
import { VERSION } from '../config';

const GetStaffInformation = async ({ identificationId }) => {
  api.defaults.baseURL = getPortalServerIp();
  return api
    .post(
      '/PortalStaff/ios/GetStaffInformation',
      IdentificationId({ identificationId })
    )
    .then((res) => res.data);
};
const GetStaffInformationForStaffGroup = async ({ staffGroupGuid }) => {
  api.defaults.baseURL = getPortalServerIp();
  const data = IdentificationId();
  data.staffGroupGuid = staffGroupGuid;
  return api
    .post('/PortalStaff/ios/GetStaffInformationForStaffGroup', data)
    .then((res) => res.data);
};

const UpdateManualStaffGroup = async ({ staffGroup }) => {
  api.defaults.baseURL = getPortalServerIp();
  const data = IdentificationId();
  data.staffGroup = staffGroup;
  return api
    .post('/PortalStaff/ios/UpdateManualStaffGroup', data)
    .then((res) => res.data);
};

const GetGroupedContactInformation = async () => {
  api.defaults.baseURL = getPortalServerIp();
  const data = IdentificationId();
  data.contactInformationFilter = {
    IncludedOnlineStates: [1, 2, 4, 8],
  };
  return api
    .post('/PortalStaff/ios/GetGroupedContactInformation', data)
    .then((res) => res.data);
};
const GetManualStaffGroups = async () => {
  api.defaults.baseURL = getPortalServerIp();
  const data = IdentificationId();
  return api
    .post('/PortalStaff/ios/GetManualStaffGroups', data)
    .then((res) => res.data.staffGroups);
};
const SearchUserDirectory = async ({ searchString, maxResults = 2000 }) => {
  api.defaults.baseURL = getPortalServerIp();
  let data = IdentificationId();
  data.searchString = searchString;
  data.maxResults = maxResults;
  return api
    .post('/PortalStaff/ios/SearchUserDirectory', data)
    .then((res) => res.data.contactInformationList);
};

const getPortalStaffRequestData = (identificationId, additionalData) => {
  const data = {
    identificationId: identificationId,
    platformType: 4,
    appType: 4000,
    versionNumber: VERSION,
  };
  if (additionalData) {
    return {
      ...data,
      ...additionalData,
    };
  }
  return data;
};

export {
  GetStaffInformation,
  GetStaffInformationForStaffGroup,
  GetGroupedContactInformation,
  GetManualStaffGroups,
  UpdateManualStaffGroup,
  SearchUserDirectory,
  getPortalStaffRequestData,
};
