import React, { useEffect, useState } from 'react';
import { FaFile, FaFileAlt, FaFilePdf } from 'react-icons/fa';
import { TiArrowForward } from 'react-icons/ti';
import Linkify from 'react-linkify';
import ActionsMessage from '../actionsmessage';
import {
  AttachmentContainer,
  AttachmentFile,
  Balloon,
  BalloonActions,
  BalloonDate,
  BalloonInfo,
  ForwardText,
  RepliedMessage,
} from './styles';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/Done';
import { getStringDate } from '../../utils';
import LoadingIcon from '../../assets/images/loading.gif';
import { Image } from './image';

import { useIntl } from 'react-intl';

const BalloonComponent = ({
  message,
  conversationId,
  isDeleted,
  userGuid,
  formatedDate,
  urlDowload,
  setAttSeleted,
  setSelectedMessage,
  setSelectedMessageForward,
  text,
  setOpen,
  scrollTo,
}) => {
  const [loading, setLoading] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (message) {
      if (message.IsForward) {
      }
    }
  }, [message]);

  const onLoad = () => {
    console.log('====================================');
    console.log('loading');
    console.log('====================================');
  };

  const onLoaded = () => {
    console.log('====================================');
    console.log('load');
    console.log('====================================');
  };

  return (
    <Balloon
      id={message.Guid}
      my={message.Sender?.StaffGuid === userGuid}
      isDeleted={message.isDeleted}
      isForward={message.IsForward === true}
      isToday={getStringDate(formatedDate).length === 5}
      style={{
        whiteSpace: 'break-spaces',
      }}
    >
      {conversationId.isGroup && (
        <div style={{ fontSize: 12, display: 'block' }}>
          {message.Sender?.StaffGuid !== userGuid
            ? message.Sender.Name + ':'
            : ''}
        </div>
      )}
      {!isDeleted && message.IsForward && (
        <ForwardText>
          <div>
            <TiArrowForward />
            {intl.formatMessage({ id: 'ForwardedFrom' })}
          </div>
          <span style={{ marginLeft: 13 }}>
            {message.ReferencedMessage?.Sender?.Name}
          </span>
        </ForwardText>
      )}
      {!isDeleted && message.IsReply && message.ReferencedMessage && (
        <RepliedMessage
          onClick={() => scrollTo(message.ReferencedMessage.Guid)}
        >
          {message.ReferencedMessage.Attachments.length > 0 && (
            <AttachmentContainer
              onClick={() => scrollTo(message.ReferencedMessage.Guid)}
            >
              {message.ReferencedMessage.Attachments.map((att, key) => {
                return (
                  <AttachmentFile key={key}>
                    {att &&
                      (att.AttachmentType === 0 ||
                        att.AttachmentType === 1) && (
                        <Image
                          att={att}
                          urlDowload={urlDowload}
                          setAttSeleted={setAttSeleted}
                        />
                      )}
                    {att && att.AttachmentType === 20 && (
                      <video controls height="250" width="250">
                        <source src={`${urlDowload}/${att.Guid}`} />
                      </video>
                    )}

                    {att && att.AttachmentType === 10 && (
                      <a href={`${urlDowload}/${att.Guid}`} download>
                        <FaFilePdf size={30} color="red" />
                      </a>
                    )}
                    {(att && att.AttachmentType === 11) ||
                      (att.AttachmentType !== 0 &&
                        att.AttachmentType !== 1 &&
                        att.AttachmentType !== 10 &&
                        att.AttachmentType !== 20 && (
                          <a href={`${urlDowload}/${att.Guid}`} download>
                            <FaFileAlt size={30} color="#666" />
                          </a>
                        ))}
                    {att && att.AttachmentType === 100 && (
                      <a href={`${urlDowload}/${att.Guid}`} download>
                        <FaFile size={30} color="#333" />
                      </a>
                    )}
                  </AttachmentFile>
                );
              })}
            </AttachmentContainer>
          )}
          {message.ReferencedMessage.Message}
        </RepliedMessage>
      )}
      {message.Attachments.length > 0 && (
        <AttachmentContainer>
          {message.Attachments.map((att, key) => {
            return (
              <AttachmentFile key={key}>
                {att &&
                  (att.AttachmentType === 0 || att.AttachmentType === 1) && (
                    <Image
                      att={att}
                      urlDowload={urlDowload}
                      setAttSeleted={setAttSeleted}
                    />
                  )}
                {att && att.AttachmentType === 20 && (
                  <video controls height="250" width="250">
                    <source src={`${urlDowload}/${att.Guid}`} />
                    <p>{att.Name}</p>
                  </video>
                )}

                {att && att.AttachmentType === 10 && (
                  <a href={`${urlDowload}/${att.Guid}`} download>
                    <FaFilePdf size={30} color="red" />
                    <p>{att.Name}</p>
                  </a>
                )}
                {(att && att.AttachmentType === 11) ||
                  (att.AttachmentType !== 0 &&
                    att.AttachmentType !== 1 &&
                    att.AttachmentType !== 10 &&
                    att.AttachmentType !== 20 && (
                      <a href={`${urlDowload}/${att.Guid}`} download>
                        <FaFileAlt size={30} color="#666" />
                        <p>{att.Name}</p>
                      </a>
                    ))}
                {att && att.AttachmentType === 100 && (
                  <a href={`${urlDowload}/${att.Guid}`} download>
                    <FaFile size={30} color="#333" />
                    <p>{att.Name}</p>
                  </a>
                )}
              </AttachmentFile>
            );
          })}
        </AttachmentContainer>
      )}
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a
            target="_blank"
            rel="noreferrer"
            style={{ color: '#039be5' }}
            href={decoratedHref}
            key={key}
          >
            {decoratedText}
          </a>
        )}
      >
        <div
          style={{
            textAlign: 'left',
            maxWidth: 480,
            wordWrap: 'break-word',
          }}
        >
          {text}
        </div>
      </Linkify>
      {!isDeleted && (
        <>
          <BalloonDate>{getStringDate(formatedDate)}</BalloonDate>
          <BalloonInfo>
            {message.Sender?.StaffGuid === userGuid && message.IsRead && (
              <DoneAllIcon style={{ color: '#039be5' }} />
            )}
            {message.Sender?.StaffGuid === userGuid && !message.IsRead && (
              <DoneIcon />
            )}
          </BalloonInfo>
          <BalloonActions>
            <ActionsMessage
              message={message}
              my={message.Sender?.StaffGuid === userGuid}
              setSelectedMessage={setSelectedMessage}
              setSelectedMessageForward={setSelectedMessageForward}
              setOpen={setOpen}
            />
          </BalloonActions>
        </>
      )}
    </Balloon>
  );
};

export { BalloonComponent };
