import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';

import { useIntl } from 'react-intl';

const ConfirmDialog = ({ open, question, handleCancel, handleOk }) => {
  const intl = useIntl();
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        {intl.formatMessage({ id: 'Confirm' })}
      </DialogTitle>
      <DialogContent>
        <span>{question}</span>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button onClick={handleOk} color="primary">
          {intl.formatMessage({ id: 'Ok' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
