import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { REACT_APP_POOL_TIME } from '../config';
import { MarkMessageRead } from '../services/chat';
import {
  DeleteConversation,
  GetMessagesForConversations,
  DeleteManualStaffGroup,
  setConversationState,
  GetOrCreateConversationByContactInformation,
} from '../services/conversations';
import { getUserGuid } from '../storage';
import { CompareLists } from '../utils';
import { useAuth } from './auth';
import { useConversation } from './conversation';
const ChatMessagesContext = createContext();
const ChatMessagesProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [conversation, setConversation] = useState();
  const [group, setGroup] = useState();
  const [oldConversation, setOldConversation] = useState();
  const [cancelPool, setCancelPool] = useState(false);
  const [hasUpdate, setHasUpdate] = useState(0);
  const { portal } = useAuth();
  const userGuid = getUserGuid();
  const { setOpenInfo, reloadConversations, conversations } = useConversation();
  const [selectedPage, setSelectedPage] = useState(false);
  const [openChat, setOpenChat] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      try {
        if (
          conversation &&
          !cancelPool &&
          portal &&
          document.visibilityState === 'visible'
        ) {
          //For now, the chat will have a maximum number of 40 messages
          GetMessagesForConversations({
            Guid: conversation.Guid,
            maxNumberMessages: 40,
          }).then((data) => {
            if (data.GetMessagesForConversationResult) {
              data.orderedMessages.forEach(async (message) => {
                if (
                  !message.IsRead &&
                  `${message.Sender.StaffGuid}` !== `${userGuid}`
                ) {
                  await MarkMessageRead({ messageGuid: message.Guid });
                }
              });
              if (
                CompareLists({
                  list1: messages,
                  list2: data.orderedMessages,
                })
              ) {
                setHasUpdate(hasUpdate == 0 ? 1 : 0);
              }

              setMessages(data.orderedMessages);
            }
          });
        }
      } catch (error) {}
    }, REACT_APP_POOL_TIME);
    return () => clearInterval(interval);
  }, [conversation, cancelPool, portal, hasUpdate]);

  const addMessageManually = ({ message }) => {
    setMessages((state) => [...state, message]);
  };

  const deleteConversation = async ({ data }) => {
    try {
      await DeleteManualStaffGroup({
        staffGroup: data,
      });
      const cs = await reloadConversations();
      console.log(cs);
      if (cs && cs.length > 0) {
        setConversation(cs[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setConversationArchive = async (guid, isArchive) => {
    try {
      await setConversationState(
        {
          Guid: guid,
        },
        isArchive
      );
      const cs = await reloadConversations();
      console.log(cs);
      if (cs && cs.length > 0) {
        setConversation(cs[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createGroupConversation = async (group) => {
    try {
      const response = await GetOrCreateConversationByContactInformation({
        contactInformation: {
          Name: group.Name,
          Priority: group.Priority,
          StaffGuid: group.GroupGuid,
        },
      });
      setSelecetedConversation({
        isGroup: true,
        ...response.conversation,
      });
    } catch (error) {
      // console.log(error)
    }
  };

  const setSelecetedConversation = (conversation, dontCloseInfo) => {
    try {
      if (portal) {
        if (oldConversation && !conversation.isGroup) {
          GetMessagesForConversations({
            Guid: oldConversation.Guid,
            maxNumberMessages: 1,
          }).then(async (data) => {
            if (data.GetMessagesForConversationResult) {
              if (data.orderedMessages.length === 0) {
                DeleteConversation({ Guid: oldConversation.Guid });
              }
            }
          });
        }
        setConversation(conversation);
        setOldConversation(conversation);
        if (!dontCloseInfo) {
          //setOpenInfo(false);
          let listUnreadMessages = [];
          setCancelPool(false);
          if (conversation) {
            GetMessagesForConversations({
              Guid: conversation.Guid,
              maxNumberMessages: 40,
            }).then(async (data) => {
              if (data.GetMessagesForConversationResult) {
                data.orderedMessages.forEach(async (message) => {
                  if (
                    !message.IsRead &&
                    `${message.Sender.StaffGuid}` !== `${userGuid}`
                  ) {
                    listUnreadMessages.push(message);
                    await MarkMessageRead({ messageGuid: message.Guid });
                  }
                });
                setMessages(data.orderedMessages);
                setHasUpdate(hasUpdate == 0 ? 1 : 0);
              }
            });
            setUnreadMessages(listUnreadMessages);
          }
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const clearConversation = () => {
    setConversation();
  };

  const setSelectedGroup = (group) => {
    try {
      if (portal) {
        setGroup(group);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <ChatMessagesContext.Provider
      value={{
        messages,
        setMessages,
        addMessageManually,
        conversation,
        setConversation,
        setSelecetedConversation,
        setCancelPool,
        hasUpdate,
        unreadMessages,
        deleteConversation,
        setConversationArchive,
        setSelectedGroup,
        group,
        clearConversation,
        selectedPage,
        setSelectedPage,
        createGroupConversation,
        openChat,
        setOpenChat,
      }}
    >
      {children}
    </ChatMessagesContext.Provider>
  );
};

function useChatMessages() {
  const context = useContext(ChatMessagesContext);
  if (!context) {
    throw new Error('useChatMessages must be used within a AppProvider');
  }
  return context;
}
export { ChatMessagesProvider, ChatMessagesContext, useChatMessages };
