import { createGlobalStyle } from 'styled-components';
import colors from './colors';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  *::-webkit-scrollbar {
    width: 2px;
    height: 7px;
  }

  /* *::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

*/
  *::-webkit-scrollbar-thumb {
    background: #999;
    border: 1px solid #999;
    border-radius: 5px;
    transition: 0.5s;
  }

  *::-webkit-scrollbar-thumb:hover {
    background: #999;
  }

  *::-webkit-scrollbar-thumb:active {
    background: #999;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 0;
  }

  *::-webkit-scrollbar-track:hover {
    background: #ffffff;
  }
  *::-webkit-scrollbar-track:active {
    background: #ffffff;
  }

  *::-webkit-scrollbar-corner {
    background: transparent;
  }
  body {
    background: ${colors.light};
    color: ${colors.primary};
    --webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
    font-weight: 500;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }
`;
