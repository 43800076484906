import styled from 'styled-components';
import theme from '../../../styles/material';
export const Body = styled.div`
  width: 400px;
  height: 500px;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Header = styled.div`
  height: 70px;
  background-color: ${theme.palette.secondary.main};
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row !important;
  column-gap: 15px;
  color: #fff;
  align-items: center;
  padding-left: 10px;
  svg {
    color: #fff;
    cursor: pointer;
    font-size: 25px;
  }
`;
export const HeaderTitle = styled.div`
  font-size: 18px;
`;
export const Title = styled.div`
  font-size: 18px;
`;

export const ItemList = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  padding: 20px 10px 20px 10px;
  width: 100%;
  margin-top: 1px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  justify-content: space-between;
  background-color: inherit !important;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  & + div {
    border-top: 0;
  }
`;
