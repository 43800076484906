import styled from 'styled-components';

export const Sidebar = styled.div`
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: 0;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  flex: 1;
  z-index: ${(props) => (props.open ? 1000 : -1000)};
  width: ${(props) => (props.open ? '425px' : '0px')};
  transform: scale3d();
  transition: width 500ms;
  /* height: 100%; */
`;
export const Container = styled.div`
  opacity: ${(props) => (props.open ? 1 : 0)};
  position: absolute;
  top: 60px;
  width: 424px;
  bottom: 0;
  overflow-y: auto;
  form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex: 1;
    width: 100%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 10;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    & > input {
      width: 100%;
      background: inherit;
      border: none;
      border-bottom: 1px solid #333;
      padding: 5px;
    }
    h6 {
      font-size: 14px;
      color: #999;
    }
    hr {
      border: none;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

export const Header = styled.div`
  width: 425px;
  height: 60px;
  background: #f0f0f0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  column-gap: 10px;
  align-items: center;
`;

export const ItemList = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  padding: 15px;
  width: 100%;
  margin-top: 1px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  justify-content: space-between;
  background-color: inherit !important;
  color: #666;
  font-size: 14px;
  cursor: pointer;
  transition: background 500ms;

  &:hover {
    background-color: #d0d0d0 !important;
  }
  & + div {
    border-top: 0;
  }
`;
